import React from "react";

const CocktailDetails = ({ cocktail }) => {
    return (
        <div className="cocktail-details">
            <h2>{cocktail.name}</h2>
            <p>{cocktail.description}</p>
            <h3>Ingredients:</h3>
            <ul className="ingredients">
                {cocktail.ingredients.map((ingredient) => (
                    <li key={ingredient.name}>
                        {ingredient.amount} <strong>{ingredient.name}</strong>
                    </li>
                ))}
            </ul>
            <p>{cocktail.instructions}</p>
        </div>
    );
};

export default CocktailDetails;