import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import CocktailDetails from './components/CocktailDetails';

const App = () => {
  const [cocktail, setCocktail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiUrl = process.env.REACT_APP_APPSYNC_API_URL;
  const apiKey = process.env.REACT_APP_APPSYNC_API_KEY;

  const fetchCocktail = async () => {
    setLoading(true);
    setError(null);
    try {
      const result = await axios.post(
        apiUrl,
        {
          query: `query {
            getRandomCocktail {
              name
              ingredients {
                name
                amount
              }
              instructions
            }
          }`
        },
        {
          headers: {
            'x-api-key': apiKey,
          }
        }
      );
      setCocktail(result.data.data.getRandomCocktail);
    }
    catch (err) {
      setError(err.message);
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchCocktail();
  }, []);

  return (
    <div className="container text-center">
      <h1>Mixicon: your cocktail lexicon</h1>
      {loading && <p>Loading cocktail...</p>}
      {error && <p>Error: {error}</p>}
      {cocktail && <CocktailDetails cocktail={cocktail} />}
      <button className='btn btn-primary mt-3' onClick={fetchCocktail}>Get a New Cocktail!</button>
    </div>
  )
}
export default App;
